import axios from "axios";

const getPropertyFromExternalAPI = async (address: string): Promise<any> => {
	console.log(address);
	let allRes;
	try {
		const externalBedBathCount = await axios.get(`https://realty-mole-property-api.p.rapidapi.com/properties?address=${address}`, {
			headers: {
				"x-rapidapi-host": "realty-mole-property-api.p.rapidapi.com",
				"x-rapidapi-key": "8337e5fd1dmsh3d7b62f77c26f6bp1b9e08jsnb37a103f9941"
			}
		});
		let realtyMoleBedrooms = externalBedBathCount.data[0].bedrooms ? externalBedBathCount.data[0].bedrooms : 2;
		let realtyMoleBathrooms = externalBedBathCount.data[0].bathrooms ? externalBedBathCount.data[0].bathrooms : 2;

		const responseArray = [
			axios.get(`https://realtymole-rental-estimate-v1.p.rapidapi.com/rentalPrice?address=${address}`, {
				headers: {
					"x-rapidapi-host": "realtymole-rental-estimate-v1.p.rapidapi.com",
					"x-rapidapi-key": "8337e5fd1dmsh3d7b62f77c26f6bp1b9e08jsnb37a103f9941"
				}
			}),
			axios.get(
				`https://api.airdna.co/client/v1/rentalizer/ltm?access_token=2c4b008762ca4e8cb9845f8dbf12d35e&address=${address}&bedrooms=${realtyMoleBedrooms}&bathrooms=${realtyMoleBathrooms}&accommodates=${realtyMoleBedrooms * 2}`
			),
			axios.get(`https://apis.estated.com/v4/property?token=vy9j3GQZffrnevoIcGog98a6HQ880h&combined_address=${address}`)
		];
		try {
			allRes = await Promise.all([...responseArray]);
			allRes.push(externalBedBathCount);
			return allRes;
		} catch (error) {
			console.log(error);
			return false;
		}
	} catch (err) {
		const responseArray = [
			axios.get(`https://realtymole-rental-estimate-v1.p.rapidapi.com/rentalPrice?address=${address}`, {
				headers: {
					"x-rapidapi-host": "realtymole-rental-estimate-v1.p.rapidapi.com",
					"x-rapidapi-key": "8337e5fd1dmsh3d7b62f77c26f6bp1b9e08jsnb37a103f9941"
				}
			}),
			axios.get(`https://api.airdna.co/client/v1/rentalizer/ltm?access_token=2c4b008762ca4e8cb9845f8dbf12d35e&address=${address}`),
			axios.get(`https://apis.estated.com/v4/property?token=vy9j3GQZffrnevoIcGog98a6HQ880h&combined_address=${address}`)
		];
		try {
			allRes = await Promise.all([...responseArray]);
			allRes.push({ data: "No data found" });
			return allRes;
		} catch (error) {
			console.log(error);
			return false;x
		}
	}
};

export default getPropertyFromExternalAPI;
