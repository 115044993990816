import React from 'react';

const ZohoSalesIQDom = (props) => {
    
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: '2ad800c55ed1c501ed21014cf9f8d7f827a959493c3ccaacb300d04f8fa09f34e377847c31842876637f74f9d4ca0544',
        values: {},
        ready: function () {},
    };
    const d = document;
    let s;
    s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.com/widget';
    let t;
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
    return (
        <React.Fragment>
            <div id='zsiqwidget'></div>
        </React.Fragment>
    );
};

export default ZohoSalesIQDom;
